:root {
  --primary-orange: #ff7321;
  --primary-black: #0f172b;
  --primary-dark-blue: #0f334a;
  --secondary-light-blue: #99b7c4;
  --secondary-white: #ffffff;
  --secondary-grey: #F5F6F7;
}

html {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-y: scroll;
}

body {
  min-height: 100%;
  margin: 0;
  padding: 0;
  background-color: var(--color-background);
}

button {
  padding: 0;
  border: none;
  background-color: transparent;
}

input[type='checkbox'],
input[type='radio'] {
  transform: scale(1.1);
  width: 1.2rem;
  height: 1.2rem;
}

.bannerContainer {
  background-color: var(--secondary-grey);
}

.banner {
  display: table;
  object-fit: contain;
  height: 117px;
  width: 100%;
  margin-bottom: 22px;
  padding: 0;
  border-radius: 5px;
}

.bannerText {
  color: var(--primary-black);
  display: table-cell;
  vertical-align: middle;
  margin: auto;
  padding: 10px;
}

.logoBackground {
  position: relative;
  width: 270px;
  min-width: 270px;
  height: 100%;
  padding: 0;
  margin: 0;
  left: -24px;
  text-align: center;
  background-color: var(--primary-dark-blue);
}

.logoBackground:after {
  display: flex;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  z-index: -1;
  background-color: var(--primary-dark-blue);

  transform-origin: bottom left;
  -ms-transform: skew(-30deg, 0deg);
  -webkit-transform: skew(-30deg, 0deg);
  transform: skew(-30deg, 0deg);
}

.appbar {
  box-sizing: border-box;
  min-width: 1290px;
  margin-bottom: 30px;
  padding: 0;
}

.toolbar {
  height: 64px;
  box-shadow: 1px 3px 7px var(--secondary-grey);
  padding: 0;
  margin: 0;
  min-width: 1200px;
  background-color: var(--secondary-white);
  z-index: 1;
}

.stack {
  margin: 0 0 0 40px;
  padding: 0;
}

.appbarImgParent {
  float: left;
  width: 100%;
  height: 100%;
  margin: -8px 0 0 0px;
  padding: 0;
}

.appbarImg {
  height: 48px;
  width: auto;
  margin: -1px 10px 0 0;
  padding: 0;
}

.appbarimgLogo {
  height: 20px;
  width: 123px;
  padding: 15px 20px;
}

.viewAssetBox {
  border-radius: 5px;
  box-shadow: 1px 3px 7px var(--secondary-grey);
  padding: 15px;
  margin: 7px;
  min-width: 580px;
}

.navigationButton:hover {
  background-color: var(--secondary-grey);
}

.selectionGridCard {
  height: 290px;
  width: 479px;
}

.AddMethodCard {
  height: 290px;
  width: 290px;
}

.detailsButtonBar {
  display: flex;
  min-width: 100%;
  justify-content: space-between;
}

.componentDataflowList {
  min-height: 30px;
  max-height: 100px;
  border: 1px solid;
  border-color: var(--primary-black);
  overflow: auto;
}

.auth {
  &__screen {
    height: 100vh;
    width: 100vw;

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  &__error-title {
    font-weight: 600;
    margin-bottom: 1rem;
  }

  &__error-message {
    padding: 1rem;
    max-width: 600px;

    font-size: 0.872rem;
  }
}

.homePageDescription {
  border-left: 3px solid;
  border-color: var(--primary-orange);
  padding-left: 15px;
  margin: 7px;
  min-width: 580px;
}

.homePageText {
  margin: 7px;
  min-width: 240px;
  display: "flex",
}

.homePageList {
  list-style-type: circle;
  padding-left: 10;
}

.homePageList > li:before {
  content: " ";
}

.genericLink:link,
.genericLink:visited {
  color: black;
  text-decoration: none;
  border-bottom: 1px solid black;
}

.genericLink:hover,
.genericLink:active {
  color: black;
  text-decoration: none;
  border-bottom: 2px solid var(--primary-orange);
}

.content {
  display: flex;
  margin: 0 auto;

  color: var(--color-text);
  padding: 2rem 1.5rem 4rem 1.5rem;

  &__sidebar {
    width: 300px;
    margin-right: 1rem;
  }

  &__main {
    flex: 1;
  }
}

.modal .modal-content {
  max-width: 800px;
}

.breadcrumbs {
  min-height: 100px;
  max-height: 100px;
}

.dropDownList {
  margin: 34px 0px 0px 0px;
  width: 400px;
  max-height: 100px;
}

.MuiBreadcrumbs-separator{
  font-size: 25px;
  font-weight: bold;
  position: relative;
  top: 15px;
}

.subscriptions-modal .modal-content {
  max-width: 1280px;
  padding: 0.75rem;
}

.subscriptions {
  &__title {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }

  &__list {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 1.5rem;
  }

  &__roles {
    user-select: none;
  }

  &__roles-input {
    display: flex;
    margin: 0.25rem 0;
    align-items: center;

    span {
      padding-top: 2px;
    }

    input {
      margin-right: 0.5rem;
    }
  }

  &__categories {
    display: flex;
  }

  &__category {
    display: flex;
    align-items: center;
    padding-right: 1rem;

    span {
      padding-left: 0.5rem;
    }
  }

  &__tabs {
    max-width: 80%;
    font-size: 0.8rem;
  }

  &__new {
    display: flex;
    margin-bottom: 0.5rem;
  }

  &__name {
    width: 270px;
    margin-right: 0.5rem;
  }

  &__frequency {
    width: 150px;
  }

  &__filters-title {
    padding: 0.5rem 0;
    font-weight: 500;
  }

  &__filters {
    gap: 2rem;
    display: grid;
    margin-bottom: 0.5rem;

    grid-template-columns: 1fr 1fr;

    .react-checkbox-tree ol {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    .rct-title {
      white-space: nowrap;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__general-buttons > button {
    margin-right: 0.5rem;
  }
}
